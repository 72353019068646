.user-profilebar img {
    width: 50px;
    border-radius: 50%;
    height: 50px;
    object-fit: cover;
}
.user-profilebar {
    position: relative;
}
body[data-page^='/event'] .banner-bg-video {
    display: none;
}
.portfolio-img video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contentbar{
    position: relative;
}
.stretegy-box .stretegy-img {position: relative;}

.stretegy-box .stretegy-img .logo-overlay {
    position: absolute;
    bottom: 0;
    padding: 10px 5px;
    background: rgb(255 255 255 / 85%);
    width: 100%;
    text-align: center;
}
.stretegy-box .stretegy-img .logo-overlay a.seraphic-logo img {
    width: 150px;
    object-fit: contain;
    transform: scale(1) !important;
}
.stretegy-box .stretegy-img img {
    transition: .4s ease;
}

.stretegy-box:hover 
 .stretegy-img img {
    transform: scale(1.1);
}
.user-profilebar .nav-item.dropdown .btn,
.user-profilebar .nav-item.dropdown a {
    padding: 5px 15px!important;
    background: transparent;
    border: none;
    display: block;
    font-size: 14px;
    line-height: 1.1;
    text-transform: capitalize;
    color: #000000;
    font-family: HARMON-BOLD;
    display: inline-block;
}
.user-profilebar .nav-item.dropdown {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.user-profilebar .nav-item.dropdown .dropdown-toggle::after {
   opacity: 0;
}
.user-profilebar .profile-image {
    width: 50px;
    height: 50px;
}
.user-profilebar .nav-item.dropdown .dropdown-toggle {
    width: 50px;
    height: 50px;
    display: inline-block;
}
.comunity-navs .user-profilebar {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0px, -50%);
}
.afterlogin {
    padding-top: 150px;
}
.mobilenav-community {
    display: none;
}
@media (max-width: 992px){
    .light-nav{
        background-color: #999999;
    }
    .mobilenav-community {
        display: block !important;
    }
    .mobilenav-community a {
        display: block;
    }
    .mobilenav-community {
        display: none;
    }
    
    .mobilenav-community a {
        display: block;
    }
    .light-nav{border-radius: 50px;}
    .mobilenav-community a.mobview {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .nav-links-menu a {
        padding: 10px 15px;
    }
    .light-nav .comunity-navs {
        
        border-radius: 50px;
    }
    .mobilenav-community a.mobview .profile-image {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    body[data-page="/community-hub"] .navbar-nav.nav-links-menu.light-nav-2 {
        padding: 20px;
        border-radius: 50px;
    }
}
@media (max-width: 767px){
    .people-bar img {
        width: 100px !important;
        height: 100px !important;
    }
    .afterlogin {
        padding-top: 130px;
    }
    .people-bar.PeopleDetailPortfolio-bar .portfolio-bg-img img {
        width: 100% !important;
        height: 100% !important;
    }
}