.strategy-boxes li {
    color: #000 !important;
}

.banner-title {
    justify-content: flex-start !important;
}

.joiningForm textarea {
    background: #EEEEEE !important;
    border: none !important;
    resize: none !important;
}

.community-banner button.btn.blue-text-right {
    float: none;
    margin: 0 auto;
    height: auto;
    padding: 12px 40px;
    line-height: inherit;
    border-radius: 0;
    width: auto !important;
    max-width: 100% !important;
}

.community-banner p {
    margin: 30px 0;
}

.resource-box {
    display: block;
    cursor: pointer;
}

.resource-box:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    transform: unset;
}

.resource-box .recource-img {
    border-radius: 5px;
    overflow: hidden;
}

select.form-control {
    height: auto;
    border: 1px solid #DEDEDE;
    padding: 17px;
    border-radius: 5px;
    outline: none;
    box-shadow: none;
    color: #212529;
    background-color: #fff;
}

.userform select.form-control {
    background-color: #fff !important;
}

.rowCust {
    width: calc(100% + var(--bs-gutter-x));
    margin-left: calc(calc(var(--bs-gutter-x) / 2) * -1);
}

.people-block h4.title {
    color: #333;
}

#appLoader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

#appLoader:not(.show) {
    display: none;
}

#appLoaderBox img {
    height: 120px;
    width: auto;
}

.help-block.help-block-error {
    color: #c60f0f;
}

.from-section.profile-page {
    background: transparent;
    padding: 0;
    float: unset;
    overflow: hidden;
}

.from-section.profile-page select {
    height: 52px;
}

.from-section.profile-page select,
.from-section.profile-page textarea {
    background-color: #EEEEEE;
    padding: 0.375rem 0.75rem;
    border: 0;
    width: 100%;
    margin-bottom: 18px;
    resize: none;
}

.title-header {
    display: flex;
    align-items: center;
}

.title-header .title {
    margin: 0 !important;
}

.title-header a,
.title-header .btn {
    margin-left: auto;
}

.title-header {
    display: flex;
    align-items: center;
    margin: 25px 0;
}

.profile-image {
    height: 120px;
    width: 120px;
    background: #eee;
    border-radius: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-size: cover;
    background-position: center;
}

.profile-image-box {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 25px 0;
    margin-bottom: 50px;
}

.profile-image-box button,
.profile-image-box a {
    margin-left: auto;
}

.profile-image-box .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.profile-image-box .actions a,
.profile-image-box .actions button {
    margin-left: 15px !important;
}

.profile-image span.icon {
    background: #0099FF;
    cursor: pointer;
    color: #fff;
    padding: 5px;
    border-radius: 50px;
    line-height: normal;
    margin-right: 0px;
    border: 4px solid #fff;
    width: 40px;
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.theme-form-section input,
.theme-form-section select,
.theme-form-section textarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: #EEEEEE;
    border: 0;
    height: 52px;
    width: 100%;
    margin-bottom: 18px;
}

.theme-form-section input:hover,
.theme-form-section input:focus,
.theme-form-section select:hover,
.theme-form-section select:focus,
.theme-form-section textarea:hover,
.theme-form-section textarea:focus {
    background-color: #EEEEEE;
}

.theme-form-section textarea {
    height: auto;
}

.btn-norm {
    min-width: 135px;
}

.blue-outline-btn {
    background-color: transparent;
    color: #0099FF;
    font-size: 20px;
    font-family: HARMON-BOLD;
    max-width: 127px;
    width: 100%;
    height: 47px;
    text-align: center;
    display: block;
    line-height: 47px;
    text-align: center;
    float: right;
    border: 1px solid #0099FF; 
}

.blue-outline-btn:hover,
.blue-outline-btn:focus {
    background-color: #0099FF;
    color: #fff;
}

.modal-extra-body {
    padding: 45px 30px;
}

body[data-page="/profile"] .aside-head {
    margin-bottom: 25px;
}

.portfolio-listing {
    margin-bottom: 25px;
}

.clickable {
    cursor: pointer;
}

.people-bar.PeopleDetailPortfolio-bar .portfolio-bg-img {
    height: auto;
}

.theme-form-section .title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 25px;
    color: #333;
    font-family: 'QUALY-REGULAR';
}

.joiningForm .thanks-box {
    text-align: center;
    padding: 15px 0;
}

.joiningForm .thanks-box p {
    margin: 5px 0;
}

.joiningForm .thanks-box h5 {
    font-family: 'QUALY-REGULAR';
    margin: 10px 0;
}

.joiningForm .thanks-box img {
    height: 75px;
    width: auto;
    margin-bottom: 25px;
}

.theme-form-group-fields input,
.theme-form-group-fields select,
.theme-form-group-fields textarea {
    margin-bottom: 0;
}

.theme-form-group-fields .form-group {
    margin-bottom: 15px;
}

.theme-form-group-fields .form-group .help-block.help-block-error {
    font-size: 13px;
}

.portfoliobar .profile-client .box-client .overlay {
    display: none;
}

.portfolio-listing .portfolio-details .text-black {
    color: #767676;
}